(function ($) {
  Drupal.behaviors.basicCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.basic-carousel-formatter', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var useResponsive = false;
        var respDots = false;
        
        // show arrows.
        var respArrows = !carousel.data('hide-arrows') ? true : false;
        // autoplay.
        var autoplay = carousel.data('slides-autoplay') ? true : false;
        // fade transition
        var fade = carousel.attr('data-slides-fade');

        if (typeof fade == 'undefined') {
          fade = false;
        } else {
          fade = true;
        }

        // carousel speed
        var carouselSpeed = parseInt(carousel.attr('data-slides-speed'));

        if (isNaN(carouselSpeed)) {
          carouselSpeed = 1000;
        }

        // slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'));

        // var largeBreakpoint = {};
        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          var useResponsive = true;
        }

        // slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // responsive - show dots for large screens
        var pcDots = carousel.attr('data-pc-dots');

        if (typeof pcDots !== 'undefined') {
          var useResponsive = true;
          var respDots = true;

          respArrows = false;
        }

        // responsive - show dots for smaller screens
        var mobileDots = carousel.attr('data-mobile-dots');

        if (typeof mobileDots !== 'undefined') {
          var useResponsive = true;
          var respDots = true;

          respArrows = false;
        }
        // settings coming from cms
        var settingsCms = {
          autoplay: autoplay,
          fade: fade,
          cssEase: 'linear',
          speed: carouselSpeed,
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll
        };
        // case for basic with dots for mobile
        var settingsResponsive = {
          autoplay: autoplay,
          fade: fade,
          cssEase: 'linear',
          speed: 1000,
          appendArrows: arrowsDiv,
          appendDots: dotsDiv,
          arrows: respArrows,
          dots: respDots,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendArrows: arrowsDiv,
                appendDots: dotsDiv,
                arrows: respArrows,
                dots: respDots,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        if (useResponsive === 'true') {
          carousel.slick(settingsCms);
        } else {
          carousel.slick(settingsResponsive);
        }
      });
    }
  };
})(jQuery);
